import { FC, SyntheticEvent } from "react";
import { Box, Popover } from "@mui/material";
import { useLocalStorage } from "usehooks-ts";

import { Rating as RatingType, useCreateShareMutation } from "../generated/graphql";
import { useLoginPush } from "../hooks/login-push";
import { useAnalyticsQueued } from "../hooks/delicious-analytics";
import { useSession } from "../hooks/auth";
import { useUpdateRating } from "../hooks/use-update-rating";
import { canonicalLink } from "../utils/routes";
import { absolutize } from "../utils/url";
import { Rating } from "./Rating";
import { Button } from "./Button";
import { ArrowRight } from "./icons/ArrowRight";
import { ShareMessage } from "./ShareMessage";


export type RatingPopoverProps = {
  canonicalContentId?: string;
  shareId?: string;
  rating?: Pick<RatingType, 'rating'> | null;
  open: boolean;
  onClose: () => void;
  isWatched: number;
  handleWatched: (ev: SyntheticEvent, active: boolean, createFeedItems: boolean) => void;
}


export const RatingPopover: FC<RatingPopoverProps> = function RatingPopover({ canonicalContentId, shareId, rating, open, onClose, isWatched, handleWatched }) {

  const { setLoginHint } = useLoginPush();
  const { track } = useAnalyticsQueued();
  const { user } = useSession();

  const [message, setMessage] = useLocalStorage(`MessageInput.${canonicalContentId || shareId}`, '');

  const link = absolutize(canonicalLink(canonicalContentId, shareId));


  const updateRating = useUpdateRating(shareId, canonicalContentId);

  const handleRatingChange = (ev: SyntheticEvent, value: number | null) => {
    if(!user) {
      track('click_rate', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to save your rating.');
      onClose();
    } else if(value !== null && value !== -1 && value !== rating?.rating) {
      track('update_rating_popover', { category: 'rating', value });
      if(!isWatched) {
        handleWatched(ev, true, false);
      }

      updateRating(value);
    }
  };


  const [createShare] = useCreateShareMutation();

  const submitShare = (ev: SyntheticEvent) => {
    ev.preventDefault();
    track('share_public', { category: 'share', origin: 'rating_popover' });

    createShare({
      variables: {
        input: {
          text: message,
          recipients: [],
          lists: [],
          link,
          isPublic: true,
          reshareOf: null,
        }
      },
      onCompleted: () => {
        setMessage('');
      }
    });
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorReference={"none"}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      PaperProps={{ sx: { borderRadius: 2, p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' } }}
      onClose={(ev: SyntheticEvent) => { ev.preventDefault(); ev.stopPropagation(); onClose(); }}
      onClick={(ev: SyntheticEvent) => { ev.preventDefault(); ev.stopPropagation(); }}
    >
      <Rating
        size='xlarge'
        value={rating?.rating || null}
        onChange={handleRatingChange}
      />

      {rating &&
        <Box sx={{ pt: 2, width: '100%' }}>
          <ShareMessage
            message={message}
            setMessage={setMessage}
            onSubmit={submitShare}
          />
          <Box sx={{ pt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button variant='contained' size='small' disabled={!message} onClick={submitShare} color='primary'>
              <ArrowRight style={{ width: '14px', marginRight: '4px' }} /> Share
            </Button>
          </Box>
        </Box>
      }
    </Popover>
  );
};
