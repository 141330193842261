import { FC } from 'react';

import { useScrollHistory } from '../hooks/scroll-history';
import { LayoutContentProps } from './layout/StaticLayout';


// This is a empty component used to prevent useScrollHistory from re-rendering the component where it is used.
export const ScrollHistory: FC<Pick<LayoutContentProps, 'scrollContainerRef'>> = function ScrollHistory({ scrollContainerRef }) {
  useScrollHistory(scrollContainerRef);
  return null;
}
