import { FC } from 'react';
import { IconContainerProps, Rating as MuiRating, RatingProps as MuiRatingProps, SxProps } from "@mui/material";
import { RatingIcon } from './icons/RatingIcon';


const IconContainer: FC<IconContainerProps> = function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}><RatingIcon value={value} highlight={props.className?.includes('-iconFilled') || false} /></span>;
}


interface RatingProps extends Omit<MuiRatingProps, 'size'> {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}

export const Rating: FC<RatingProps> = function Rating(props) {

  const sx: SxProps = {
    fontSize: undefined,
    gap: 1,
    mr: -1,
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 1 auto',
  };

  let size: 'small' | 'medium' | 'large';

  if(props.size === 'xlarge') {
    sx.fontSize = '3.5rem';
    size = 'large';
  } else {
    size = props.size || 'medium';
  }
  const propsWithoutSize = { ...props, size: undefined };

  return (
    <MuiRating
      max={5}
      precision={1}
      sx={{ ...sx, ...props.sx }}
      readOnly={false}
      disabled={false}
      highlightSelectedOnly
      IconContainerComponent={IconContainer}
      {...propsWithoutSize}
      size={size}
      onClick={ev => ev.stopPropagation()}
    />
  );
};
