import { RefObject, useEffect, useState } from "react";

import { useBrowserLayoutEffect } from ".";
import { useLocationWithMeta } from "./history";
import { isStorageAvailable, Preferences } from "./storage";


const storagePrefix = "scrollhistory";
const maxScrollHistoryLength = 10;


const restoreScrollPosition = (scrollContainer: HTMLElement, locationKey: string) => {
  if(!isStorageAvailable()) {
    console.error("restoreScrollPosition: Preferences not available, can't restore scroll position");
    return;
  }
  // console.log("Restoring scroll position for", locationKey);
  Preferences.get({ key: `${storagePrefix}.${locationKey}` }).then(({ value }) => {
    if (value) {
      const { position } = JSON.parse(value);
      scrollContainer.scrollTop = position;
    }
  }).catch(err => console.error(`Error getting scroll position: ${err}`));
}


const saveScrollPosition = (scrollContainer: HTMLElement, position: number, locationKey: string) => {
    if(!isStorageAvailable()) {
    console.error("saveScrollPosition: Preferences not available, can't save scroll position");
    return;
  }
  // console.log("Saving scroll position", position, "for", locationKey);
  const data = JSON.stringify({ position, createdAt: Date.now() });
  Preferences.set({ key: `${storagePrefix}.${locationKey}`, value: data }).catch(err => console.error(`Error saving scroll position: ${err}`));
}


const pruneStorage = async () => {
  if(!isStorageAvailable()) {
    console.error("pruneStorage: Preferences not available, can't prune scroll history");
    return;
  }
  try {
    const { keys } = await Preferences.keys();
    const scrollKeys = keys.filter(key => key.startsWith(storagePrefix));
    const scrollEntries: [string, number][] = [];
    for (const key of scrollKeys) {
      const { value } = await Preferences.get({ key });
      if (value) {
        const { createdAt } = JSON.parse(value);
        scrollEntries.push([ key, createdAt ]);
      }
    }
    scrollEntries.sort((a, b) => a[1] - b[1]);
    if(scrollEntries.length > maxScrollHistoryLength) {
      const toRemove = scrollEntries.slice(0, scrollEntries.length - maxScrollHistoryLength).map(([ key ]) => key);
      for (const key of toRemove) {
        Preferences.remove({ key }).catch(err => console.error(`Error removing scroll history entry: ${err}`));
      }
    }
  } catch(err) {
    console.error(`Error pruning scroll history: ${err}`);
  }
};


export function useScrollHistory(scrollContainerRef: RefObject<HTMLElement>) {
  const { location, action, timestamp } = useLocationWithMeta();

  const [ willRestoreScrollPosition, setWillRestoreScrollPosition ] = useState(false);


  useEffect(() => {
    if (scrollContainerRef.current && location) {
      const el = scrollContainerRef.current;
      const listener = () => {
        saveScrollPosition(el, el.scrollTop, `${location.pathname}.${location.key}`);
      };
      el.addEventListener("scroll", listener);
      return () => el.removeEventListener("scroll", listener);
    }
  }, [location, scrollContainerRef]);


  useEffect(() => {
    const renderThreshold = 3000; // only restore delayed scroll position if possible within 3s of location change
    const age = Date.now() - (timestamp || 0);
    if (scrollContainerRef.current && willRestoreScrollPosition && location && timestamp) {
      if(age < renderThreshold) {
        // console.log("Restoring scroll position after delay for", location.pathname, location.key);
        restoreScrollPosition(scrollContainerRef.current, `${location.pathname}.${location.key}`);
        pruneStorage().catch(err => console.error(`Error pruning scroll history: ${err}`));
      } else {
        // console.warn("Delayed scroll position restore skipped due to slow render", { age });
      }
      setWillRestoreScrollPosition(false);
    }
  }, [location, scrollContainerRef, willRestoreScrollPosition, setWillRestoreScrollPosition, timestamp]);


  useBrowserLayoutEffect(() => {
    const renderThreshold = 1000; // only restore scroll position if effect is run within 1s of location change
    const age = Date.now() - (timestamp || 0);
    // on POP (ie back/forward) restore scroll position if available
    if(location && action === 'POP' && !willRestoreScrollPosition) {
      if(!timestamp) {
        // console.log("No timestamp available for scroll position restore");
      } else if(age > renderThreshold) {
        // console.warn("Scroll position restore skipped due to timestamp being too old");
      } else if(!scrollContainerRef.current) {
        // console.log("Scroll position restore delayed due to scroll container not being available");
        setWillRestoreScrollPosition(true);
      } else {
        // console.log("Restoring scroll position for", location.pathname, location.key);
        restoreScrollPosition(scrollContainerRef.current, `${location.pathname}.${location.key}`);
        pruneStorage().catch(err => console.error(`Error pruning scroll history: ${err}`));
      }
    }
  }, [location, action, timestamp, scrollContainerRef]);

}
